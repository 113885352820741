import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
// import { styled } from '@mui/material/styles';
import { Button, Link, Typography } from '@mui/material';
// const StyledBox = styled('div')(({ theme }) => ({
//   alignSelf: 'center',
//   width: '100%',
//   textAlign: 'center',
//   height: 400,
//   marginTop: theme.spacing(8),
//   borderRadius: theme.shape.borderRadius,
//   outline: '1px solid',
//   boxShadow: '0 0 12px 8px hsla(220, 25%, 80%, 0.2)',
//   outlineColor: 'hsla(220, 25%, 80%, 0.5)',
//   [theme.breakpoints.up('sm')]: {
//     marginTop: theme.spacing(0),
//     height: 700,
//   },
//   ...theme.applyStyles('dark', {
//     boxShadow: '0 0 24px 12px hsla(210, 100%, 25%, 0.2)',
//     outlineColor: 'hsla(210, 100%, 80%, 0.1)',
//   }),
// }));

export default function Appointments() {
  const month = new Date().getMonth()
  console.log("month - ",month);
  return (
    <Box
      id="AppointmentContainer"
      sx={(theme) => ({
        width: '100%',
        backgroundRepeat: 'no-repeat',
        // backgroundImage:
        //   'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)',
        // ...theme.applyStyles('dark', {
        //   backgroundImage:
        //     'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)',
        // }),
        pt: { xs: 2, sm: 6 },
          pb: { xs: 8, sm: 16 },
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 7, sm: 10 },
          pb: { xs: 4, sm: 6 },
        }}
      >
          <Box
            sx={{
              width: { sm: '100%', md: '60%' },
              textAlign: { sm: 'left', md: 'center' },
            }}
          >
            <h2 className='appointH2'>Book A <span className='red-text'>Free</span> Meeting</h2>
            <p className='appointP'>To Get Started.</p>
            <Typography variant="body1" sx={{ color: 'text.secondary' }}>
              See what our customers love about our products. Discover how we excel in
              efficiency, durability, and satisfaction. Join us for quality, innovation,
              and reliable support.
            </Typography>
            <Button id='appointMeet' size='large' variant='contained'
              sx={{
                width: { sm: '100%', md: '43%' },
                textAlign: { sm: 'left', md: 'center' },
                mt: { xs: 4, sm: 10 },
              }}
            >
              <Link href='https://calendly.com/sitinovations-jvod/60min'>
              <Typography fontSize={'2rem'} fontFamily={'oswald'} letterSpacing={'0.12rem'} variant="body" sx={{ color: 'text.primary' }}>Book Right Now</Typography>
              </Link>
            </Button>
          </Box>
      </Container>

    </Box>
  );
}


