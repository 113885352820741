import React, { useState } from 'react';
import Slider from 'react-slick';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import ads from '../../assets/ads.svg';
import content from '../../assets/content.svg';
import devlopment from '../../assets/development.svg';
import uiux from '../../assets/uiux.svg';
import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const images = [
  ads,
  content,
  devlopment,
  uiux,
];

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
};

const ContactForm = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    businessName: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = await fetch('https://formspree.io/YOUR_FORM_ID', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

    if (response.ok) {
      alert('Form submitted successfully!');
      setFormData({
        fullName: '',
        email: '',
        phone: '',
        businessName: '',
        message: '',
      });
    } else {
      alert('Form submission failed!');
    }
  };

  return (
    <Container id="ContactForm" sx={{ py: { xs: 8, sm: 16 } }}>
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <Typography fontFamily={'oswald'} component="h2" variant="h4" sx={{ color: 'text.primary' }}>
            Contact Us
          </Typography>
          <Typography fontFamily={'poppins'} fontSize={'1rem'} variant="body1" sx={{ color: 'text.secondary', mb: { xs: 2, sm: 4 } }}>
            We'd love to hear from you. Fill out the form below and our team will get back to you shortly.
          </Typography>
          <Box
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
            }}
            onSubmit={handleSubmit}
          >
            <TextField
              required
              label="Full Name"
              variant="outlined"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
            />
            <TextField
              required
              label="Email"
              variant="outlined"
              fullWidth
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            <TextField
              required
              label="Phone No"
              variant="outlined"
              fullWidth
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
            />
            <TextField
              required
              label="Business Name"
              variant="outlined"
              fullWidth
              name="businessName"
              value={formData.businessName}
              onChange={handleChange}
            />
            <TextField
              required
              label="Message"
              variant="outlined"
              fullWidth
              name="message"
              value={formData.message}
              onChange={handleChange}
            />
            <Button
              size="large"
              variant="contained"
              type="submit"
              sx={{ mt: { xs: 4, sm: 6 }, bgcolor: 'primary.main', color: 'primary.contrastText' }}
            >
              <Typography fontSize="1.5rem" fontFamily="oswald" letterSpacing="0.12rem">
                Submit
              </Typography>
            </Button>
            <Typography variant="body2" sx={{ mt: 2 }}>
              By submitting this form, you agree to our <Link component={RouterLink} to='/privacy-policy'>Privacy Policy</Link> and <Link component={RouterLink} to="/terms-of-service">Terms of Service</Link>.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} sx={{ display: { xs: 'none', md: 'block' } }}>
          <Card variant="outlined" sx={{ height: '100%', width: '100%' }}>
            <Box sx={{ m: 'auto', width: '100%', height: '100%' }}>
              <Slider {...settings}>
                {images.map((image, index) => (
                  <Box key={index} sx={{ height: '100%' }}>
                    <img
                      src={image}
                      alt={`carousel-image-${index + 1}`}
                      style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                  </Box>
                ))}
              </Slider>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ContactForm;
