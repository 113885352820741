import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Card, CardContent, Grid, Link} from "@mui/material";


export default function AboutComponent() {
  return (
    <Box
      id="vision"
      sx={(theme) => ({
        width: "100%",
        backgroundRepeat: "no-repeat",
        backgroundImage:
          "radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)",
        ...theme.applyStyles("dark", {
          backgroundImage:
            "radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)",
        }),
      })}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack
          spacing={2}
          useFlexGap
          sx={{ alignItems: "center", width: { xs: "100%", sm: "70%" } }}
        >
          <Typography
            variant="h1"
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: "center",
              fontSize: "clamp(3rem, 10vw, 3.5rem)",
            }}
          >
            <Typography
              component="span"
              variant="h1"
              sx={(theme) => ({
                fontSize: "inherit",
                color: "primary.main",
                ...theme.applyStyles("dark", {
                  color: "primary.light",
                }),
              })}
            >
              Sanskaar IT Inovations
            </Typography>
          </Typography>



          <Container sx={{}}>
            {/* Additional Section (optional) */}
      <Grid container spacing={6} sx={{ mb: { xs: 6, sm: 8 } }}>
        <Grid item xs={12}>
          <Card sx={{ backgroundColor: 'background.paper' }}>
            <CardContent>
              <Typography fontFamily={'oswald'} component="h2" variant="h5" sx={{ color: 'text.primary', mb: 2 }} textAlign={'center'}>
                Why Choose Us?
              </Typography>
              <Typography fontFamily={'poppins'} fontSize={'1rem'} variant="body1" sx={{ color: 'text.secondary', textAlign: 'justify' }}>
                We are committed to delivering high-quality digital solutions that help businesses grow and succeed in the online world.
                From UI/UX design to web development and content creation, we offer everything your brand needs to shine.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {/* Our Vision Section */}
      <Grid container spacing={6} id="ourVision" sx={{ mb: { xs: 6, sm: 8 } }}>
        <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center' }} textAlign={'center !important'}>
          <Typography fontFamily={'oswald'} component="h2" variant="h4" sx={{ color: 'text.primary' }}>
            Our Vision
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ m: 'auto', width: '100%' }}>
            <Typography fontFamily={'poppins'} fontSize={'1rem'} variant="body1" sx={{ color: 'text.secondary', textAlign: 'justify' }}>
              Our vision is to be at the forefront of digital transformation, guiding businesses to establish a powerful online presence.
              At Sanskaar IT Innovations (SITInovations), we strive to deliver excellence in UI/UX design, web development, digital marketing,
              and content creation, ensuring that every brand we work with stands out in the digital realm.
            </Typography>
          </Box>
        </Grid>
      </Grid>

      {/* Our Mission Section */}
      <Grid container spacing={6} id="ourMission" sx={{ mb: { xs: 6, sm: 8 } }}>
        <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center', order: { xs: 2, md: 1 } }}>
          <Box sx={{ m: 'auto', width: '100%' }}>
            <Typography fontFamily={'poppins'} fontSize={'1rem'} variant="body1" sx={{ color: 'text.secondary', textAlign: 'justify' }}>
              Our mission at SITInovations is to empower businesses with tailor-made digital solutions that encompass innovative UI/UX design,
              seamless web development, strategic digital marketing, and high-quality content creation, including ad and product shoots.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center', order: { xs: 1, md: 2 } }}>
          <Typography fontFamily={'oswald'} component="h2" variant="h4" sx={{ color: 'text.primary' }}>
            Our Mission
          </Typography>
        </Grid>
      </Grid>

      {/* About Us Section */}
      <Grid container spacing={6} id="aboutUs">
        <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography fontFamily={'oswald'} component="h2" variant="h4" sx={{ color: 'text.primary' }}>
            About Us
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ m: 'auto', width: '100%' }}>
            <Typography fontFamily={'poppins'} fontSize={'1rem'} variant="body1" sx={{ color: 'text.secondary', textAlign: 'justify' }}>
              Sanskaar IT Innovations (SITInovations) is a leader in providing comprehensive digital services. Our expertise includes UI/UX design,
              web development, digital marketing, and content creation, such as ad and product shoots. We craft unique digital experiences that
              resonate with audiences and drive business success.
            </Typography>
          </Box>
        </Grid>
      </Grid>

      
    </Container>






          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={1}
            useFlexGap
            sx={{ pt: 2, width: { xs: "100%", sm: "auto" } }}
          >
            <Link href='https://calendly.com/sitinovations-jvod/60min' rel="noreferrer" target='_blank' >
            <Button id="quoteBTN">Book Free Consultancy Now!</Button>
            </Link>
          </Stack>
          <Typography variant="caption" sx={{ textAlign: 'center' }}>
            By clicking &quot;Book Free Consulatancy Now!&quot; Button, you agree to our&nbsp;
            <Link to="/" color="primary">
              Terms & Conditions
            </Link>
            .
          </Typography>
        </Stack>
      </Container>
    </Box>
  );
}
