import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { ReactComponent as SanskaarLogo } from '../../assets/NewLogoAgainFull.svg';
import { Instagram } from '@mui/icons-material';
import { FacebookIcon } from 'lucide-react';
import X from '@mui/icons-material/X';

function Copyright() {
  return (
    <Typography variant="body2" sx={{ color: 'text.secondary', mt: 1 }}>
      {'Copyright © '}
      <Link href="https://www.sitinovations.in/" rel='noreferrer' target='_blank'>Sanskaar IT Inovations&nbsp;</Link>
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function Footer() {
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 4, sm: 8 },
        py: { xs: 4, sm: 5 },
        textAlign: { sm: 'center', md: 'left' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            minWidth: { xs: '100%', sm: '60%' },
          }}
        >
          <Box sx={{ width: { xs: '100%', sm: '60%' }, cursor: 'pointer' }} onClick={() => window.location.href = '/'}>
            <SanskaarLogo width={'12rem'} />
            <Typography variant="body2" gutterBottom sx={{ fontWeight: 600, mt: -2 }}>
              Book Your Meeting Now!
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary', mb: 2 }}>
              Take the first step with <b>Sanskaar IT Inovations</b> towards your Digital Thrive.
            </Typography>
            <Stack direction="row" spacing={1} useFlexGap>
              <Link href='https://calendly.com/sitinovations-jvod/60min' rel='noopener' target='_blank'>
                <Button variant="contained" color="primary" sx={{ flexShrink: 0 }}>
                  <b>Book Here</b>
                </Button>
              </Link>
            </Stack>
            <Typography variant="body2" sx={{ color: 'text.secondary', mb: 2 }}>
              Send your queries at <b>support@sitinovations.in</b>
            </Typography>
          </Box>
        </Box>
        
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 4
          }}
        >
        {/* Sitemap Navigation */}
        <Box component="nav" aria-label="Footer Sitemap" sx={{ display: { xs: 'flex', sm: 'flex' }, flexDirection: 'column', gap: 1 }}>
          <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
            Sitemap
          </Typography>
          <Link
            color="text.secondary"
            variant="body2"
            href="#services"
            sx={{ cursor: 'pointer' }}
          >
            Services
          </Link>
          <Link
            color="text.secondary"
            variant="body2"
            href="#testimonials"
            sx={{ cursor: 'pointer' }}
          >
            Testimonials
          </Link>
          <Link
            color="text.secondary"
            variant="body2"
            href="#highlights"
            sx={{ cursor: 'pointer' }}
          >
            Highlights
          </Link>
          <Link
            color="text.secondary"
            variant="body2"
            href="#AppointmentContainer"
            sx={{ cursor: 'pointer' }}
          >
            Book Meet
          </Link>
          <Link
            color="text.secondary"
            variant="body2"
            href="#faqs"
            sx={{ cursor: 'pointer' }}
          >
            FAQs
          </Link>
        </Box>

        {/* Company Navigation */}
        <Box component="nav" aria-label="Company Navigation" sx={{ display: { xs: 'flex', sm: 'flex' }, flexDirection: 'column', gap: 1 }}>
          <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
            Company
          </Typography>
          <Link
            color="text.secondary"
            variant="body2"
            href="#aboutUs"
            sx={{ cursor: 'pointer' }}
          >
            About us
          </Link>
          <Link
            color="text.secondary"
            variant="body2"
            href="#ContactForm"
            sx={{ cursor: 'pointer' }}
          >
            Contact Us
          </Link>
          <Link
            color="text.secondary"
            variant="body2"
            href="#careers"
            sx={{ cursor: 'pointer' }}
          >
            Careers
          </Link>
        </Box>

        {/* Legals Navigation */}
        <Box component="nav" aria-label="Legal Navigation" sx={{ display: { xs: 'flex', sm: 'flex' }, flexDirection: 'column', gap: 1 }}>
          <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
            Legals
          </Typography>
          <Link
            color="text.secondary"
            variant="body2"
            component={RouterLink}
            to='/terms-of-service'
          >
            Terms
          </Link>
          <Link
            color="text.secondary"
            variant="body2"
            component={RouterLink}
            to='/privacy-policy'
          >
            Privacy
          </Link>
        </Box>
        </Box>
      </Box>

      {/* Bottom Section */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pt: { xs: 4, sm: 8 },
          width: '100%',
          borderTop: '1px solid',
          borderColor: 'divider',
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'center', sm: 'flex-start' },
          gap: { xs: 2, sm: 0 },
        }}
      >
        <div>
          <Link color="text.secondary" variant="body2" component={RouterLink} to="/privacy-policy">
            Privacy Policy
          </Link>
          <Typography sx={{ display: 'inline', mx: 0.5, opacity: 0.5 }}>
            &nbsp;•&nbsp;
          </Typography>
          <Link color="text.secondary" variant="body2" component={RouterLink} to="/terms-of-service">
            Terms of Service
          </Link>
          <Copyright />
        </div>
        <Stack
          direction="row"
          spacing={1}
          useFlexGap
          sx={{ justifyContent: 'left', color: 'text.secondary' }}
        >
          <IconButton
            color="inherit"
            href="https://www.instagram.com/sitinovations"
            aria-label="Instagram"
            sx={{ alignSelf: 'center' }}
            rel='noreferrer'
            target='_blank'
          >
            <Instagram />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://www.facebook.com/profile.php?id=61563847771783"
            aria-label="Facebook"
            sx={{ alignSelf: 'center' }}
            rel='noreferrer'
            target='_blank'
          >
            <FacebookIcon />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://x.com/SITinovations"
            aria-label="X"
            sx={{ alignSelf: 'center' }}
            rel='noreferrer'
            target='_blank'
          >
            <X />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://www.linkedin.com/company/sanskaar-it-inovations/"
            aria-label="LinkedIn"
            sx={{ alignSelf: 'center' }}
            rel='noreferrer'
            target='_blank'
          >
            <LinkedInIcon />
          </IconButton>
        </Stack>
      </Box>
    </Container>
  );
}
