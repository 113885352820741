import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './landing-page/LandingPage';
import TermsOfService from './assets/legals/TermsofService';
import PrivacyPolicy from './assets/legals/PrivacyPolicy';
import './App.css';
import AboutUs from './landing-page/Pages/AboutUs';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/" element={<LandingPage />} />
      </Routes>
    </Router>
  );
}

export default App;
