import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/system';
import blmgLogo from '../../assets/blmgLogo.png';
import blmgLogoDark from '../../assets/blmgLogo-dark.png';
import tfhLogo from '../../assets/theforecasthouseLogo.png';
import samacharsevaLogo from '../../assets/samacharsevaLogo.jpg'
import companyLogo from '../../assets/companyLogo.png';

const userTestimonials = [
  {
    avatar: <Avatar alt="The Forecast House" src="/static/images/avatar/1.jpg" />,
    name: 'Dr. Nand Kishore Purohit',
    occupation: 'Director, The Forecast House',
    testimonial:
      "Sanskaar IT Inovations did a great job for us. Their Digital Brand Marketing & Content Creation really helped our business. They are professional and easy to work with. I recommend them to everyone.",
  },
  {
    avatar: <Avatar alt="BL Mohta" src="/static/images/avatar/2.jpg" />,
    name: 'B.L. Mohta',
    occupation: 'Director, BLMG',
    testimonial:
      "Sanskaar IT Inovations did an excellent job for our digital brand marketing and content creation. Their work helped boost our online presence. They are professional and reliable. I highly recommend them.",
  },
  {
    avatar: <Avatar alt="Samachar Seva" src="/static/images/avatar/2.jpg" />,
    name: 'Neeraj Joshi',
    occupation: 'Director, Samachar Seva',
    testimonial:
      "We were in a critical situation when our news portal, Samachar Seva, was hacked. Sanskaar IT Inovations stepped in and not only restored our website quickly but also strengthened our security to prevent future incidents. Their expertise and prompt response were crucial in getting us back online. We highly recommend their services!",
  },
  {
    avatar: <Avatar alt="Coming Soon" src="/static/images/avatar/2.jpg" />,
    name: 'Your Name',
    occupation: 'CEO & MD, Your Company',
    testimonial:
      "You can also get a valuable review of Sanskar IT Innovations from our list of most happy customers. Just one meeting away",
  },
];

const whiteLogos = [
  tfhLogo,
  blmgLogoDark,
  samacharsevaLogo,
  companyLogo,
];

const darkLogos = [
  tfhLogo,
  blmgLogo,
  samacharsevaLogo,
  companyLogo,
];

const logoStyle = {
  width: '64px',
  opacity: 0.3,
};

export default function Testimonials() {
  const theme = useTheme();
  const logos = theme.palette.mode === 'light' ? darkLogos : whiteLogos;

  return (
    <Container
      id="testimonials"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography component="h2" variant="h4" sx={{ color: 'text.primary' }}>
          Testimonials
        </Typography>
        <Typography variant="body1" sx={{ color: 'text.secondary' }}>
          See what our customers love about our products. Discover how we excel in
          efficiency, durability, and satisfaction. Join us for quality, innovation,
          and reliable support.
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {userTestimonials.map((testimonial, index) => (
          <Grid item xs={12} sm={6} md={4} key={index} sx={{ display: 'flex' }}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                flexGrow: 1,
                p: 1,
              }}
            >
              <CardContent>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {testimonial.testimonial}
                </Typography>
              </CardContent>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  pr: 2,
                }}
              >
                <CardHeader
                  avatar={testimonial.avatar}
                  title={testimonial.name}
                  subheader={testimonial.occupation}
                />
                <img
                  src={logos[index]}
                  alt={`Logo ${index + 1}`}
                  style={logoStyle}
                />
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
      <p className='fontDance'>Looking forward to your testimonial as our next <span className='red-text strong'>Success Story!</span></p>
    </Container>
  );
}
