import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function FAQ() {
  const [expanded, setExpanded] = React.useState(false);

  const faqs = [
    {
      question: "What services does your company offer?",
      answer:
        "We provide a wide range of services, including web development, web design, digital marketing, SEO, content creation, and branding. Our goal is to help your business succeed online with a comprehensive digital strategy.",
    },
    {
      question: "How can I contact your support team?",
      answer:
        "You can reach our support team through the contact form on our website, by emailing support@SITInovations.in , or by calling our customer service number at +91-88 24 22 6032.",
    },
    {
      question: "Where are you located?",
      answer:
        "We are located in [City, Country]. However, we serve clients globally and can work with you no matter where you are located.",
    },
    {
      question: "What technologies do you use for web development?",
      answer:
        "We use a variety of modern technologies including HTML, CSS, JavaScript, React, Angular, Node.js, and more. We select the best tools and technologies based on the specific needs of your project.",
    },
    {
      question: "Can you update and maintain my existing website?",
      answer:
        "Yes, we offer website maintenance and update services to ensure your website remains up-to-date, secure, and running smoothly.",
    },
    {
      question: "What is digital marketing, and why is it important?",
      answer:
        "Digital marketing involves promoting your products or services online through various channels such as social media, search engines, email, and more. It is important because it helps you reach a larger audience, engage with customers, and drive more traffic and sales to your business.",
    },
    {
      question: "Can you help with social media marketing?",
      answer:
        "Yes, we offer comprehensive social media marketing services including strategy development, content creation, posting, and advertising on platforms such as Facebook, Instagram, Twitter, LinkedIn, and more.",
    },
    {
      question: "How much do your services cost?",
      answer:
        "Our pricing varies based on the specific services you need and the scope of your project. Please contact us for a customized quote.",
    },
    {
      question: "What payment methods do you accept?",
      answer:
        "We accept various payment methods including credit/Debit cards, UPI, bank transfers, and more. We will provide detailed payment instructions once we discuss your project.",
    },
    {
      question: "Do you offer any discounts or special offers?",
      answer:
        "We occasionally run promotions and special offers. Please check our website or contact us directly to learn about any current discounts.",
    },
    {
      question: "How do you manage projects and ensure timely delivery?",
      answer:
        "We use project management tools, set deadlines, and communicate with clients. Our team follows a structured process to ensure that all tasks are completed on time and to the highest quality standards.",
    },
    {
      question: "Will I have a dedicated point of contact for my project?",
      answer:
        "Yes, you will have a dedicated project manager who will be your main point of contact throughout the project. They will keep you updated on progress and address any questions or concerns you may have.",
    },
  ];

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container
      id="faq"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        sx={{
          color: "text.primary",
          width: { sm: "100%", md: "60%" },
          textAlign: { sm: "left", md: "center" },
        }}
      >
        Frequently asked questions
      </Typography>
      <Box sx={{ width: "100%" }}>
        {faqs.map((faq, index) => (
          <Accordion
            key={index}
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}d-content`}
              id={`panel${index}d-header`}
            >
              <Typography component="h3" variant="subtitle2">
                {faq.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body2"
                gutterBottom
                sx={{ maxWidth: { sm: "100%", md: "70%" } }}
              >
                {faq.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Container>
  );
}
