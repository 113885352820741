import React from 'react';

const TermsOfService = () => {
  return (
    <div style={{ padding: '20px' }}>
      <header>
        <h1>Terms of Service</h1>
      </header>
      <main>
        <section>
          <h2>1. Introduction</h2>
          <p>
            Welcome to Sanskaar IT Inovations. These Terms of Service govern your use of our website located at https://www.sitinovations.in operated by Sanskaar IT Inovations.
          </p>
        </section>
        {/* <section>
          <h2>2. Accounts</h2>
          <p>
            When you create an account with us, you must provide us with information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account on our Service.
          </p>
        </section> */}
        <section>
          <h2>3. Intellectual Property</h2>
          <p>
            The Service and its original content, features and functionality are and will remain the exclusive property of Sanskaar IT Inovations and its licensors.
          </p>
        </section>
        <section>
          <h2>4. Termination</h2>
          <p>
            We may terminate or suspend your account and bar access to the Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms.
          </p>
        </section>
        <section>
          <h2>5. Changes</h2>
          <p>
            We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.
          </p>
        </section>
        <section>
          <h2>6. Contact Us</h2>
          <p>
            If you have any questions about these Terms, please contact us at support@sitinovations.in or sitinovations@rediffmail.com.
          </p>
        </section>
      </main>
    </div>
  );
};

export default TermsOfService;
